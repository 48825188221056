import React, { useState } from 'react'
import Layout from '../components/Layout';
import Coord from '../components/Work/Coord';
import Course from '../components/Work/Course';
import coordenacao from '../images/svg/coordenacao.svg';
import cursinho from '../images/svg/cursinho.svg';

const Work = () => {

  const [area, setArea] = useState('cursinho');

  return (
    <Layout>
      <div className="container px-5 md:px-20 py-10">
        <div className="flex flex-wrap md:flex-nowrap gap-10 mb-10">
          <div className="w-full md:w-1/2">
            <div className="text-primary text-4xl font-bold landing-4">Escolha a área que você deseja fazer parte:</div>
            <select className="rounded-full border-primary font-bold mt-5 mb-3 py-0"
              onChange={(e) => { setArea(e.target.value) }}>
              <option value="cursinho">Cursinho Pró</option>
              <option value="aulaParticular">Aulas particulares</option>
              <option value="coordenacao">Coordenação</option>
            </select>

            <div className='text-2xl font-bold text-gray-800 pr-5 mt-5'>
              Ficamos felizes de saber que você se interessa em fazer parte da nossa equipe.
              No momento não temos vagas abertas, porém conclua seu cadastro abaixo e faça parte do nosso banco de talentos.
            </div>
          </div>
          <div className="w-full md:w-1/2">
            {area === 'coordenacao' && <img className="w-full md:-mt-10" src={coordenacao} alt="Extensivo" />}
            {area !== 'coordenacao' && <img className="w-full md:-mt-10" src={cursinho} alt="intensivo" />}
          </div>
        </div>

        {area === 'cursinho' || area === 'aulaParticular'
          ? <Course area={area}></Course>
          : <Coord></Coord>
        }
      </div>

    </Layout >
  )
}

export default Work
